import { createSlice } from '@reduxjs/toolkit';
import { depositRateByAmount, depsoitAmountByRate, mortgageAmountByDeposit } from 'views/mortgage/mortgageUtils';

const initialState = {
    propertyValue: 358995,
    depositRate: 15,
    interestRate: 2.18,
    depositAmount: 0,
    miscellaneous: 10000,
    mortgageTerm: 30,
    mortgageAmount: 0,
    monthlySavings: 3300,
    loanBeginDate: 1633794803188
};

// Calculate Mortgage details
initialState.depositAmount = depsoitAmountByRate(initialState.propertyValue, initialState.depositRate);
initialState.mortgageAmount = mortgageAmountByDeposit(initialState.propertyValue, initialState.depositAmount);

const mortgageSlice = createSlice({
    name: 'mortgage',
    initialState,
    reducers: {
        updateMortgageDetails: (state, action) => {
            const { key, value } = action.payload;
            const { propertyValue, depositAmount, depositRate } = state;

            state[key] = value; // Set prop & value to state

            switch (key) {
                case 'propertyValue':
                    state.mortgageAmount = mortgageAmountByDeposit(value, depositAmount);
                    state.depositAmount = depsoitAmountByRate(value, depositRate);
                    break;
                case 'depositRate':
                    state.depositAmount = depsoitAmountByRate(propertyValue, value);
                    break;
                case 'depositAmount': {
                    // On `depositAmount` change, depositRate, mortgageAmount
                    state.depositRate = depositRateByAmount(propertyValue, value);
                    state.mortgageAmount = mortgageAmountByDeposit(propertyValue, value);
                    break;
                }
                default:
                    break;
            }
        }
    }
});

export const { updateMortgageDetails } = mortgageSlice.actions;

export default mortgageSlice.reducer;
