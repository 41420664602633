import React, { lazy } from 'react';

// project imports
// import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CommonLayout from 'layout/CommonLayout';

// mortgage routing
const MortgageDashboard = Loadable(lazy(() => import('views/mortgage')));
const StampDutyCalculator = Loadable(lazy(() => import('calculators/stampduty')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: <CommonLayout />,
    children: [
        {
            path: '/mortgage-calculator',
            element: <MortgageDashboard />
        },
        {
            path: '/stamp-duty-calculator',
            element: <StampDutyCalculator />
        }
    ]
};

export default MainRoutes;
