import React, { lazy } from 'react';
import DashboardLayout from 'layout/DashboardLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from './ProtectedRoute';
import ProfileSettings from 'views/settings';

// Dashboard routing
// const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const Portfolio = Loadable(lazy(() => import('views/portfolio')));

// ===========================|| SYSTEM ROUTING ||=========================== //

const DashboardRoutes = {
    path: '/',
    element: <ProtectedRoute component={DashboardLayout} />,
    children: [
        {
            path: '/portfolio',
            element: <Portfolio />
        },
        {
            path: '/settings',
            element: <ProfileSettings />
        }
    ]
};

export default DashboardRoutes;
