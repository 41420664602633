import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import Tracker from 'tracker';
import { AuthProvider } from 'context/useAuth';

// ===========================|| APP ||=========================== //
/**
 * 
 Reference: https://medium.com/@samshapiro/firebase-local-development-with-firestore-cloud-functions-and-react-c29c188bd60e
 Protected Routes: https://ui.dev/react-router-protected-routes-authentication/
 */

const App = () => {
    const customization = useSelector((state) => state.customization);
    Tracker.initialize();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
