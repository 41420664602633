import React from 'react';

// material-ui
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { changeUserPassword } from 'auth';

// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// ===========================|| PROFILE  - SECURITY ||=========================== //

const Security = () => (
    <Formik
        initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmedPassword: '',
            submit: null
        }}
        validationSchema={Yup.object().shape({
            currentPassword: Yup.string().max(255).required('Password is required'),
            newPassword: Yup.string().min(6, 'Too short').max(255).required('Password is required'),
            confirmedPassword: Yup.string().min(6, 'Too short').max(255).required('Passwords should match')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            let message = 'Something went wrong. Please try again';
            try {
                if (values.newPassword !== values.confirmedPassword) {
                    message = 'Password confirmation did not match!';
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                } else {
                    await changeUserPassword(values.currentPassword, values.newPassword);
                    setStatus({ success: 'Password successfully updated.' });
                    setSubmitting(false);
                    // resetForm();
                }
            } catch (err) {
                console.log(err);
                const errCode = err && err.code;
                if (errCode === 'auth/wrong-password') {
                    message = 'Current password is not correct. Try again';
                }
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        }}
    >
        {({ errors, status, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={gridSpacing}>
                    <Grid item sm={6} md={8}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <SubCard title="Change Password">
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={Boolean(touched.currentPassword && errors.currentPassword)}>
                                                <InputLabel htmlFor="outlined-adornment-current-password">Current password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-current-password"
                                                    type="password"
                                                    value={values.currentPassword}
                                                    name="currentPassword"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Current password"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth error={Boolean(touched.newPassword && errors.newPassword)}>
                                                <InputLabel htmlFor="outlined-adornment-current-password">New Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-new-password"
                                                    type="password"
                                                    name="newPassword"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="New Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}>
                                                <InputLabel htmlFor="outlined-adornment-confirm-password">Re-enter New Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-confirm-password"
                                                    type="password"
                                                    name="confirmedPassword"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Re-enter New Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                        {errors.submit && (
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    paddingLeft: '24px'
                                                }}
                                            >
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}
                                        {status && status.success && (
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    paddingLeft: '24px'
                                                }}
                                            >
                                                <FormHelperText
                                                    sx={{
                                                        color: '#008000'
                                                    }}
                                                >
                                                    {status.success}
                                                </FormHelperText>
                                            </Box>
                                        )}
                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Change Password
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*   <Grid item sm={6} md={4}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <SubCard title="Delete Account">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                To deactivate your account, first delete its resources. If you are the only owner of any
                                                teams, either assign another owner or deactivate the team.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row">
                                                <AnimateButton>
                                                    <Button variant="outlined" size="small" color="error">
                                                        Deactivate Account
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </form>
        )}
    </Formik>
);

export default Security;
