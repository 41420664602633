import axios from 'axios';
import React, { useEffect } from 'react';
import { signInWithCredentials, signOutUser, registerWithEmailAndPassword, auth } from 'auth';

const authContext = React.createContext();

const resetUserAuthentication = () => {
    localStorage.removeItem('tkn');
};

// Response Interceptor
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 403) {
            resetUserAuthentication();
        }
        return Promise.reject(error);
    }
);

const setAxiosDefaultHeaders = (obj) => {
    axios.defaults.headers.common.Authorization = `Bearer ${obj.token}`; // Set auth header at single point to make use for subsequent calls
};

export const getUserBasicInfo = (user) => {
    if (!user) return {};
    return {
        id: user.uid,
        displayName: user.displayName || `${user.firstName} ${user.lastName}`,
        email: user.email,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        // Extra app fields
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        country: user.country,
        phone: user.phone
    };
};

// Reference : https://johnwcassidy.medium.com/firebase-authentication-hooks-and-context-d0e47395f402
const onAuthStateChange = (callback) => {
    // Attach oauth state change handler
    auth.onAuthStateChanged((user) => {
        callback(user || {});
    });
};

// eslint-disable-next-line
export function AuthProvider({ children }) {
    const token = localStorage.getItem('tkn');
    if (token) {
        setAxiosDefaultHeaders({ token });
    }

    const [isAuthenticated, setIsAuthenticated] = React.useState(!!token);
    const [currentUser, setCurrentUser] = React.useState({});

    const postLoginProcess = async (user) => {
        // Set user token in local storage
        localStorage.setItem('tkn', user.accessToken);
        // Setup authorization header in axios
        setAxiosDefaultHeaders({ token: user.accessToken });

        // Set authenticated flag in the context
        setIsAuthenticated(true);

        // Fetch current user details
        const response = await axios.get('/api/me');
        let appUser = user;
        if (response.status === 200) {
            appUser = {
                ...user,
                ...response.data.user
            };
        }

        // Set current user state in the context
        setCurrentUser(getUserBasicInfo(appUser));
    };

    const triggerLogin = async (values) => {
        await signInWithCredentials(values.email, values.password);
        // OAuth state change triggers and intern calls `postLoginProcess`
    };

    const triggerLogout = async () => {
        await signOutUser();
        localStorage.removeItem('tkn');
        setCurrentUser({});
        setIsAuthenticated(false);
    };

    const triggerSignup = async (values) => {
        const user = await registerWithEmailAndPassword(values);
        if (!user || user.error) {
            throw user;
        }
        // OAuth state change triggers and intern calls `postLoginProcess`
    };

    useEffect(() => {
        const setCurrentUserInfo = (user) => {
            if (user && Object.keys(user).length > 0) {
                // Trigger post authentication process
                postLoginProcess(user);
            }
        };
        onAuthStateChange(setCurrentUserInfo);
    }, []);

    return (
        <authContext.Provider value={{ isAuthenticated, triggerLogin, triggerLogout, triggerSignup, currentUser }}>
            {children}
        </authContext.Provider>
    );
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}
