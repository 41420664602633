import { makeStyles } from '@mui/styles';
import React from 'react';
import { Outlet } from 'react-router';
import Footer from 'ui-component/commons/Footer';
import AppBar from 'ui-component/extended/AppBar';

// style constant
const useStyles = makeStyles((theme) => ({
    header: {
        paddingTop: '30px',
        overflowX: 'hidden',
        overflowY: 'clip',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0px'
        }
    },
    sectionWhite: {
        paddingTop: '80px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '60px'
        }
    },
    content: {
        ...theme.typography.mainContent,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: 'auto',
        marginRight: 'auto',
        boxSizing: 'border-box',
        maxWidth: '1200px'
    }
}));

const CommonLayout = () => {
    const classes = useStyles();
    return (
        <>
            <div id="home" className={classes.header}>
                <AppBar />
            </div>
            <main className={classes.content}>
                <Outlet />
            </main>
            <Footer />
        </>
    );
};

export default CommonLayout;
