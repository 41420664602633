import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import SystemRoutes from './SystemRoutes';
import DashboardRoutes from './DashboardRoutes';
import LandingRoutes from './LandingRoutes';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    return useRoutes([LandingRoutes, MainRoutes, AuthenticationRoutes, SystemRoutes, DashboardRoutes]);
}
