import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/styles';

import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';

// assets
import { IconUser, IconLogin, IconHome2 } from '@tabler/icons';
import MenuIcon from '@mui/icons-material/Menu';

// elevation scroll
function ElevationScroll(props) {
    const { children, window } = props;
    const theme = useTheme();
    const borderColor = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined
    });

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : borderColor,
            color: theme.palette.text.dark
        }
    });
}

// ===========================|| MINIMAL LAYOUT APP BAR ||=========================== //

const AppBar = ({ ...others }) => {
    const [drawerToggle, setDrawerToggle] = React.useState(false);
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    return (
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar>
                        <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                            <RouterLink to="/">
                                <Logo />
                            </RouterLink>
                        </Typography>
                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}>
                            {/* <Button color="inherit" component={RouterLink} to="/login" target="_blank">
                                Home
                            </Button> */}
                            <Button color="inherit" component={Link} href="/login">
                                Login
                            </Button>
                            <Button component={Link} href="/signup" disableElevation variant="contained" color="secondary">
                                Create Account
                            </Button>
                        </Stack>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                                <div
                                    sx={{
                                        width: 'auto'
                                    }}
                                    role="presentation"
                                    onClick={drawerToggler(false)}
                                    onKeyDown={drawerToggler(false)}
                                >
                                    <List>
                                        <ListItemButton component={Link} href="#">
                                            <ListItemIcon>
                                                <IconHome2 />
                                            </ListItemIcon>
                                            <ListItemText primary="Home" />
                                        </ListItemButton>
                                        <ListItemButton component={Link} href="/login">
                                            <ListItemIcon>
                                                <IconLogin />
                                            </ListItemIcon>
                                            <ListItemText primary="Login" />
                                        </ListItemButton>
                                        <ListItemButton component={Link} href="/signup">
                                            <ListItemIcon>
                                                <IconUser />
                                            </ListItemIcon>
                                            <ListItemText primary="Create Account" />
                                        </ListItemButton>
                                    </List>
                                </div>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default AppBar;
