import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from 'context/useAuth';

const PublicRoute = ({ component: Component }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Navigate to="/portfolio" replace /> : <Component />;
};

PublicRoute.propTypes = {
    component: PropTypes.func
};

export default PublicRoute;
