import { defaultCurrency, defaultLanguage } from 'store/constant';
import moment from 'moment';
import Lodash from 'lodash/core';

export const depsoitAmountByRate = (propertyValue, depsoitRate) => Math.round(propertyValue * (depsoitRate / 100));

export const depositRateByAmount = (propertyValue, depositAmount) => parseFloat(((depositAmount / propertyValue) * 100).toFixed(2));

export const mortgageAmountByDeposit = (propertyValue, depositAmount) => propertyValue - depositAmount;

export const mortgageAmountByLTV = (propertyValue, LTV) => {
    if (Lodash.isEmpty(LTV) && LTV <= 0) {
        return propertyValue;
    }

    return Math.round(propertyValue * (LTV / 100));
};

export const totalMonthsToSave = (amount, savings) => amount / savings;

export const getMonthlyPayment = (mortgage) => {
    if (mortgage.mortgageTerm === 0) {
        return mortgage.propertyValue;
    }
    const noOfPayments = mortgage.mortgageTerm * 12;
    if (mortgage.interestRate === 0) {
        return parseFloat((mortgage.mortgageAmount / noOfPayments).toFixed(2));
    }
    const monthlyInterestRate = mortgage.interestRate / 100 / 12;

    const mathPowOperation = (1 + monthlyInterestRate) ** noOfPayments;

    const amount = (mortgage.mortgageAmount * (monthlyInterestRate * mathPowOperation)) / (mathPowOperation - 1);
    return parseFloat(amount.toFixed(2));
};

export const getFundsReadyByMonth = (mortgage) => {
    let monthlySaving = mortgage.monthlySavings;
    if (mortgage.monthlySavings < 1) {
        monthlySaving = 1;
    }
    const totalAmountRequired = mortgage.depositAmount + mortgage.miscellaneous;
    return totalMonthsToSave(totalAmountRequired, monthlySaving);
};

export const getFutureDate = (date, term, type = 'years') =>
    moment(date || new Date())
        .add(term, type)
        .format('MMM YYYY');

export const renderFormattedCurrency = (value, franctions = 2, notation = 'standard') =>
    new Intl.NumberFormat(defaultLanguage, {
        style: 'currency',
        notation,
        currency: defaultCurrency,
        maximumFractionDigits: franctions
    }).format(value);

export const renderFormattedPercentage = (value) =>
    new Intl.NumberFormat(defaultLanguage, {
        style: 'percent',
        maximumFractionDigits: 2
    }).format(value / 100);
