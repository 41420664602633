import { createSlice } from '@reduxjs/toolkit';
import config from 'config';

export const initialState = {
    isOpen: ['dashboard'], // for active default menu. default: dashboard
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: false
};

const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        toggleCustomization: (state, action) => {
            state.opened = action.payload;
        },
        toggleCustomizationMenu: (state, action) => {
            state.isOpen = [action.payload];
        },
        setAppFontFamily: (state, action) => {
            state.fontFamily = action.payload;
        },
        setAppBorderRadius: (state, action) => {
            state.borderRadius = action.payload;
        },
        setCustomization: (state, action) => {
            state.fontFamily = action.payload.fontFamily;
            state.borderRadius = action.payload.borderRadius;
        },
        resetCustomization: (state) => {
            state.fontFamily = config.fontFamily;
            state.borderRadius = config.borderRadius;
        }
    }
});

export const { toggleCustomization, toggleCustomizationMenu, setAppFontFamily, setAppBorderRadius, setCustomization, resetCustomization } =
    customizationSlice.actions;

export default customizationSlice.reducer;
