/* eslint-disable no-unused-vars  */
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    GoogleAuthProvider,
    connectAuthEmulator,
    updateProfile,
    updatePassword,
    EmailAuthProvider,
    reauthenticateWithCredential
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, collection, doc, setDoc, getDocs, query, where, getDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

if (process.env.NODE_ENV === 'development') {
    console.log('=== USING AUTH, FIRESTORE EMULATORS ====');
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
}

const USER_COLLECTION = 'users';
const DB_COLLECTION = collection(db, USER_COLLECTION);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogleProvider = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const { user } = res;

        const docRef = doc(db, USER_COLLECTION, user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            const names = user.displayName.split(' ');
            const userCredentials = {
                firstName: names[0],
                lastName: names[1],
                email: user.email,
                photoURL: user.photoURL,
                createdAt: new Date().toISOString(),
                userId: user.uid
            };
            await setDoc(doc(db, USER_COLLECTION, user.uid), userCredentials);
        }
        return user;
    } catch (err) {
        return { error: err };
    }
};

const signInWithCredentials = (email, password) => signInWithEmailAndPassword(auth, email, password);
const signOutUser = () => signOut(auth);

const reAuthenticate = (currentPassword) => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, cred);
};

const changeUserPassword = async (currentPassword, newPassword) => {
    await reAuthenticate(currentPassword);
    const user = auth.currentUser;
    return updatePassword(user, newPassword);
};

const registerWithEmailAndPassword = async (values) => {
    const newUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password
    };
    try {
        const q = query(DB_COLLECTION, where('email', '==', `${newUser.email}`));
        const docSnap = await getDocs(q);

        if (!docSnap.empty) {
            return {
                error: {
                    message: 'Email already exists!'
                }
            };
        }

        return createUserWithEmailAndPassword(auth, newUser.email, newUser.password)
            .then(async (userCredential) => {
                // Signed in
                const { user } = userCredential;
                const userCredentials = {
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    email: newUser.email,
                    createdAt: new Date().toISOString(),
                    userId: user.uid
                };
                await setDoc(doc(db, USER_COLLECTION, user.uid), userCredentials);
                await updateProfile(auth.currentUser, { displayName: `${newUser.firstName} ${newUser.lastName}` });
                return user;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return {
                    error: {
                        code: errorCode,
                        message: errorMessage
                    }
                };
            });
    } catch (err) {
        return { error: err };
    }
};

export { auth, signInWithCredentials, registerWithEmailAndPassword, signOutUser, signInWithGoogleProvider, changeUserPassword };
