import mixpanel from 'mixpanel-browser';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

const ENV_CHECK = process.env.NODE_ENV === 'production';

const Tracker = {
    initialize: () => {
        mixpanel.init('ab37c3a939b16d16ba990327399417b0', { debug: true, ignore_dnt: true });
    },
    track: (name, obj) => {
        if (ENV_CHECK) {
            mixpanel.track(name, obj || '');
        }
    }
};

export default Tracker;
