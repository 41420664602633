import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customization/customizationSlice';
import mortgageReducer from './mortgage/mortgageSlice';
import propertyReducer from './property/propertySlice';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    mortgage: mortgageReducer,
    properties: propertyReducer
});

export default reducer;
