import React from 'react';
import useAuth from 'context/useAuth';

// material-ui
import { makeStyles } from '@mui/styles';
import { Avatar, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography, Box } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import Avatar1 from 'assets/images/users/user-round.svg';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// style constant
const useStyles = makeStyles({
    accountAvatar: {
        width: '100px',
        height: '100px',
        margin: '0 auto'
    },
    accountContent: {
        textAlign: 'center'
    }
});

// ===========================|| PROFILE 3 - PROFILE ||=========================== //

const Profile = () => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    if (!currentUser || !currentUser.id) {
        return null;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                displayName: currentUser.displayName || '',
                email: currentUser.email || '',
                company: currentUser.company || '',
                country: currentUser.country || '',
                phone: currentUser.phone || ''
            }}
            validationSchema={Yup.object().shape({
                displayName: Yup.string().max(255).required('Name is required')
            })}
            onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
                try {
                    const response = await axios.post(`/api/user/${currentUser.id}`, values);
                    setSubmitting(false);
                    if (response.status === 200) {
                        setStatus({ success: 'Details successfully updated.' });
                    } else {
                        setStatus({ error: response.data.message });
                    }
                } catch (err) {
                    console.log(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, status, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} sm={4} md={4}>
                            <SubCard title="Profile Picture" contentClass={classes.accountContent}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Avatar alt="User" src={Avatar1} className={classes.accountAvatar} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="center">
                                            Upload/Change Your Profile Image
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AnimateButton>
                                            <Button variant="contained" size="small" color="secondary">
                                                Upload Avatar
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <SubCard title="Edit Account Details">
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={Boolean(touched.displayName && errors.displayName)}>
                                            <InputLabel htmlFor="outlined-adornment-display-name">Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-display-name"
                                                type="text"
                                                value={values.displayName || ''}
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Name"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                            <InputLabel htmlFor="outlined-adornment-email">Email address</InputLabel>
                                            <OutlinedInput
                                                disabled
                                                id="outlined-adornment-email"
                                                type="text"
                                                value={values.email || ''}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Email address"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth error={Boolean(touched.company && errors.company)}>
                                            <InputLabel htmlFor="outlined-adornment-company">Company</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-company"
                                                type="text"
                                                value={values.company || ''}
                                                name="company"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Company"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth error={Boolean(touched.country && errors.country)}>
                                            <InputLabel htmlFor="outlined-adornment-company">Country</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-country"
                                                type="text"
                                                value={values.country || ''}
                                                name="country"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="country"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                                            <InputLabel htmlFor="outlined-adornment-phone">Phone number</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-phone"
                                                type="text"
                                                value={values.phone || ''}
                                                name="phone"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Phone number"
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*                         <Grid item md={6} xs={12}>
                            <TextField id="outlined-basic8" fullWidth label="Currency" defaultValue="£" />
                        </Grid> */}

                                    {errors.submit && (
                                        <Box
                                            sx={{
                                                mt: 3,
                                                paddingLeft: '24px'
                                            }}
                                        >
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    {status && status.success && (
                                        <Box
                                            sx={{
                                                mt: 3,
                                                paddingLeft: '24px'
                                            }}
                                        >
                                            <FormHelperText
                                                sx={{
                                                    color: '#008000'
                                                }}
                                            >
                                                {status.success}
                                            </FormHelperText>
                                        </Box>
                                    )}

                                    <Grid item xs={12}>
                                        <Stack direction="row">
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Change Details
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default Profile;
