import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// 404 routing
const Error404 = Loadable(lazy(() => import('views/pages/maintenance/Error404')));

// ===========================|| SYSTEM ROUTING ||=========================== //

const SystemRoutes = {
    path: '/',
    // element: <MinimalLayout />,
    children: [
        {
            path: '*', // 404
            element: <Error404 />
        }
    ]
};

export default SystemRoutes;
