// assets
import { IconDashboard, IconDeviceAnalytics, IconHome, IconBriefcase, IconBrandGoogleAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconHome,
    IconBriefcase,
    IconBrandGoogleAnalytics
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        {
            id: 'portfolio',
            title: 'My Portfolio',
            type: 'item',
            url: '/portfolio',
            icon: icons.IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'analyser',
            title: 'Analyser',
            type: 'item',
            url: '/analyser',
            icon: icons.IconBrandGoogleAnalytics,
            breadcrumbs: false
        },
        {
            id: 'mortgage-calculator',
            title: 'Mortgage',
            type: 'item',
            url: '/mortgage-calculator',
            icon: icons.IconHome,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
