import React, { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import PublicRoute from './PublicRoute';

// Landing
const LandingPage = Loadable(lazy(() => import('views/pages/landing')));

// ===========================|| MAIN ROUTING ||=========================== //

const LandingRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <PublicRoute component={LandingPage} />
        }
    ]
};

export default LandingRoutes;
