import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from 'context/useAuth';

const ProtectedRoute = ({ component: Component }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Component /> : <Navigate to="/login" replace />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.func
};

export default ProtectedRoute;
