import axios from 'axios';

const { createSlice, createEntityAdapter, createAsyncThunk } = require('@reduxjs/toolkit');

export const mockedPropertiesInitialState = [
    {
        id: '1235666',
        address: '22 Pilton Gardens',
        purchaseDate: 1634509342477,
        purchasePrice: 67000,
        estimatedValue: 67000,
        rentalIncome: 750,
        mortgage: {
            id: '98765456789',
            type: 'purchase',
            repaymentMethod: 'interest_only',
            ltv: 75,
            interestRate: 4.5,
            term: 30,
            startDate: 1634509342477,
            amount: 50250,
            monthlyCost: 254.61
        }
    }
];

export const fetchProperties = createAsyncThunk('properties/fetchProperties', async () => {
    const response = await axios.get('/api/properties');
    return response.data;
});

export const addProperty = createAsyncThunk('properties/add', async (property) => {
    const response = await axios.put('/api/properties', property);
    return response.data;
});

export const deleteProperty = createAsyncThunk('properties/delete', async (propertyId) => {
    const response = await axios.delete(`/api/properties/${propertyId}`);
    return response.data;
});

const propertiesAdapter = createEntityAdapter({});
/* const emptyInitialState = propertiesAdapter.getInitialState();
const filledState = propertiesAdapter.upsertMany(emptyInitialState, mockedPropertiesInitialState); */

const propertySlice = createSlice({
    name: 'properties',
    initialState: propertiesAdapter.getInitialState({
        loading: false,
        hasErrors: false
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProperties.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProperties.fulfilled, (state, action) => {
                state.loading = false;
                state.hasErrors = false;
                if (action.payload.status === 'success') {
                    propertiesAdapter.setAll(state, action.payload.data);
                }
            })
            .addCase(fetchProperties.rejected, (state) => {
                state.loading = false;
                state.hasErrors = true;
            })
            .addCase(addProperty.pending, (state) => {
                state.loading = true;
            })
            .addCase(addProperty.fulfilled, (state, action) => {
                state.loading = false;
                state.hasErrors = false;
                if (action.payload.status === 'success') {
                    propertiesAdapter.addOne(state, action.payload.data);
                }
            })
            .addCase(addProperty.rejected, (state) => {
                state.loading = false;
                state.hasErrors = true;
            })
            .addCase(deleteProperty.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProperty.fulfilled, (state, action) => {
                state.loading = false;
                state.hasErrors = false;
                if (action.payload.status === 'success') {
                    propertiesAdapter.removeOne(state, action.payload.propertyId);
                }
            })
            .addCase(deleteProperty.rejected, (state) => {
                state.loading = false;
                state.hasErrors = true;
            });
    }
});

export const propertiesSelectors = propertiesAdapter.getSelectors((state) => state.properties);
export default propertySlice.reducer;
